.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: auto;
}

.swiper-slide img {
  height: 30vw;
  @media only screen and (max-width: 500px) { 
    height: 60vw;
  }
}

.swiper-scrollbar-drag {
  background: rgb(255, 255, 255, 70%);
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #000;
}

.swiper-pagination-bullet-active {
  background: #fff;
}